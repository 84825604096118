import { Component, Inject, OnInit } from '@angular/core';
import { IBillingService, BILLING_SERVICE } from '../../shared/services/billing.service.interface';



@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  public statistics = null;

  public contracts = [];
  public customers = [];

  public arrivedToday = [];
  public arrivedYesterday = [];
  public arrivedThisMonth = [];
  public arrivedLastMonth = [];
  public arrivedThisYear = [];
  public arrivedLastYear = [];

  public leftToday = [];
  public leftYesterday = [];
  public leftThisMonth = [];
  public leftLastMonth = [];
  public leftThisYear = [];
  public leftLastYear = [];

  public documentToday = [];
  public documentYesterday = [];
  public documentThisMonth = [];
  public documentLastMonth = [];
  public documentThisYear = [];
  public documentLastYear = [];

  public countToday = [];
  public countYesterday = [];
  public countThisMonth = [];
  public countLastMonth = [];
  public countThisYear = [];
  public countLastYear = [];



  constructor(@Inject(BILLING_SERVICE) private service: IBillingService) { }

  ngOnInit(): void {
    this.requestStatistics();
  }

  requestStatistics() {
    this.statistics = null;
    this.service.requestStatistics().subscribe(response => {
      this.statistics = response;

      this.contracts = Object.keys(response.arrivedToday);
      this.customers = Object.keys(response.documentToday);

      this.arrivedToday = Object.values(response.arrivedToday);
      this.arrivedYesterday = Object.values(response.arrivedYesterday);
      this.arrivedThisMonth = Object.values(response.arrivedThisMonth);
      this.arrivedLastMonth = Object.values(response.arrivedLastMonth);
      this.arrivedThisYear = Object.values(response.arrivedThisYear);
      this.arrivedLastYear = Object.values(response.arrivedLastYear);

      this.leftToday = Object.values(response.leftToday);
      this.leftYesterday = Object.values(response.leftYesterday);
      this.leftThisMonth = Object.values(response.leftThisMonth);
      this.leftLastMonth = Object.values(response.leftLastMonth);
      this.leftThisYear = Object.values(response.leftThisYear);
      this.leftLastYear = Object.values(response.leftLastYear);

      this.documentToday = Object.values(response.documentToday);
      this.documentYesterday = Object.values(response.documentYesterday);
      this.documentThisMonth = Object.values(response.documentThisMonth);
      this.documentLastMonth = Object.values(response.documentLastMonth);
      this.documentThisYear = Object.values(response.documentThisYear);
      this.documentLastYear = Object.values(response.documentLastYear);

      this.countToday = Object.values(response.countToday);
      this.countYesterday = Object.values(response.countYesterday);
      this.countThisMonth = Object.values(response.countThisMonth);
      this.countLastMonth = Object.values(response.countLastMonth);
      this.countThisYear = Object.values(response.countThisYear);
      this.countLastYear = Object.values(response.countLastYear);
    });
  }
}
