<nes-header></nes-header>
<nes-portlet [breadcrumb]="['Home', 'Statistiche']" [title]="'Statistiche'" [loading]="false" [withoutBorder]="true">
  <p *ngIf="!statistics">
    Calcolo in corso...
  </p>
  <nes-portlet-title [title]="'Ingressi e uscite'">
  </nes-portlet-title>
  <table *ngIf="statistics" class="statistics">
    <tr>
      <td colspan="2"></td>
      <td *ngFor="let contract of contracts">{{ contract }}</td>
    </tr>
    <tr>
      <td rowspan="6">Ingressi</td>
      <td>oggi</td>
      <td *ngFor="let value of arrivedToday">{{ value }}</td>
    </tr>
    <tr>
      <td>ieri</td>
      <td *ngFor="let value of arrivedYesterday">{{ value }}</td>
    </tr>
    <tr>
      <td>questo mese</td>
      <td *ngFor="let value of arrivedThisMonth">{{ value }}</td>
    </tr>
    <tr>
      <td>il mese scorso</td>
      <td *ngFor="let value of arrivedLastMonth">{{ value }}</td>
    </tr>
    <tr>
      <td>quest'anno</td>
      <td *ngFor="let value of arrivedThisYear">{{ value }}</td>
    </tr>
    <tr>
      <td>l'anno scorso</td>
      <td *ngFor="let value of arrivedLastYear">{{ value }}</td>
    </tr>
    <tr>
      <td rowspan="6">Uscite</td>
      <td>oggi</td>
      <td *ngFor="let value of leftToday">{{ value }}</td>
    </tr>
    <tr>
      <td>ieri</td>
      <td *ngFor="let value of leftYesterday">{{ value }}</td>
    </tr>
    <tr>
      <td>questo mese</td>
      <td *ngFor="let value of leftThisMonth">{{ value }}</td>
    </tr>
    <tr>
      <td>il mese scorso</td>
      <td *ngFor="let value of leftLastMonth">{{ value }}</td>
    </tr>
    <tr>
      <td>quest'anno</td>
      <td *ngFor="let value of leftThisYear">{{ value }}</td>
    </tr>
    <tr>
      <td>l'anno scorso</td>
      <td *ngFor="let value of leftLastYear">{{ value }}</td>
    </tr>
  </table>
  <nes-portlet-title [title]="'DDT'">
  </nes-portlet-title>
  <table *ngIf="statistics" class="statistics">
    <tr>
      <td colspan="2"></td>
      <td *ngFor="let customer of customers">{{ customer.split('\n')[0] }}</td>
    </tr>    
    <tr>
      <td rowspan="6">DDT</td>
      <td>oggi</td>
      <td *ngFor="let value of documentToday">{{ value }}</td>
    </tr>
    <tr>
      <td>ieri</td>
      <td *ngFor="let value of documentYesterday">{{ value }}</td>
    </tr>
    <tr>
      <td>questo mese</td>
      <td *ngFor="let value of documentThisMonth">{{ value }}</td>
    </tr>
    <tr>
      <td>il mese scorso</td>
      <td *ngFor="let value of documentLastMonth">{{ value }}</td>
    </tr>
    <tr>
      <td>quest'anno</td>
      <td *ngFor="let value of documentThisYear">{{ value }}</td>
    </tr>
    <tr>
      <td>l'anno scorso</td>
      <td *ngFor="let value of documentLastYear">{{ value }}</td>
    </tr>
  </table>
  <nes-portlet-title [title]="'Giacenze'">
  </nes-portlet-title>
  <table *ngIf="statistics" class="statistics">
    <tr>
      <td colspan="2"></td>
      <td *ngFor="let contract of contracts">{{ contract }}</td>
    </tr>
    <tr>
      <td rowspan="6">Giacenze</td>
      <td>oggi</td>
      <td *ngFor="let value of countToday">{{ value }}</td>
    </tr>
    <tr>
      <td>ieri</td>
      <td *ngFor="let value of countYesterday">{{ value }}</td>
    </tr>
    <tr>
      <td>questo mese</td>
      <td *ngFor="let value of countThisMonth">{{ value }}</td>
    </tr>
    <tr>
      <td>il mese scorso</td>
      <td *ngFor="let value of countLastMonth">{{ value }}</td>
    </tr>
    <tr>
      <td>quest'anno</td>
      <td *ngFor="let value of countThisYear">{{ value }}</td>
    </tr>
    <tr>
      <td>l'anno scorso</td>
      <td *ngFor="let value of countLastYear">{{ value }}</td>
    </tr>
  </table>
  <button type="button" mat-button [disableRipple]="true" (click)="requestStatistics()" class="form-submit-button" *ngIf="statistics">
    <span class="label">Aggiorna</span>
  </button>
</nes-portlet>
